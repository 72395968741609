<template>
  <nav class="main-page-menu">
    <router-link
      class="white-link-button"
      exact
      :to="{ name: 'monitoring' }"
      active-class="active"
      >{{ $ml.get("menu.visits") }}</router-link
    >
    <router-link
      v-if="isAddUser()"
      class="white-link-button"
      :to="{ name: 'adduser' }"
      active-class="active"
      >{{ $ml.get("menu.new_visitor") }}</router-link
    >
  </nav>
</template>

<script>
export default {
  name: "MainPageMenu",
  methods: {
    isAddUser() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("useradd") ||
        this.$auth.user().roles.includes("useredit") ||
        this.$auth.user().roles.includes("userdel")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables.scss";

.white-link-button.active {
  background: $primary;
  color: #ffffff;
}
</style>
